import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";
import PropertyModel from "../../models/property/property";
import { ResponseModel } from "../../models/shared/responseModel";
import { logoutUser } from "../../functions/logout";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const SubmitTenantApplication = async (formData: FormData) => {
  return new Promise<any>((resolve) => {
    axios
      .post(BaseUrl + "api/form/submitTenantApplication", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.status === false) {
          showSnackBar("error", response.data.message);
        } else {
          showSnackBar("success", "Successfully Registered");
        }
        resolve(response.data.data);
      })
      .catch(function (error) {
        resolve(error.message);
        showSnackBar("error", error.message);
      });
  });
};

export const GetToLetProperties = async () => {
  return new Promise<PropertyModel[]>((resolve) => {
    axios
      .get(BaseUrl + "api/form/getToLetProperties")
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        resolve([]);
        showSnackBar("error", error.message);
      });
  });
};


export const GetTenantApplicationsByPropertyId = async (
  propertyId: string,
  token: string
) => {
  return new Promise<ResponseModel>((resolve) => {
    axios
      .get(BaseUrl + "api/form/getApplicationsByPropertyId", {
        params: {
          propertyId: propertyId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logoutUser();
          showSnackBar("error", "Your session has expired, please log again.");
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        } else {
          showSnackBar("error", error.message);
        }
      });
  });
};