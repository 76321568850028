import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const getLandlordDetais = async (propertyId: string, token: string) => {
  var landlord;

  await axios
    .get(BaseUrl + "api/property/getLandlordDetails", {
      params: {
        propertyId: propertyId,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.data.status === false) {
        showSnackBar("error", response.data.message);
      } else {
        landlord = response.data.data;
      }
    })
    .catch(function (error) {
      console.log(error);
      showSnackBar("error", error.message);
    });

  return landlord;
};
