import {
  Typography,
  Checkbox,
  Dialog,
  FormHelperText,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import TenantApplicationDisclaimer from "./disclaimer";
import { Controller, FieldErrorsImpl, UseFormSetValue } from "react-hook-form";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";

interface Props {
  control: any;
  firstName: string;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
  disclaimerAccepted: boolean;
  setDisclaimerAcceted: UseFormSetValue<TenantApplicationModel>;
}

export const TenantApplicationConfirm: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  let firstName = props.firstName;

  function toggleShowDisclaimer() {
    setShowDisclaimer(!showDisclaimer);
  }
  function acceptDisclaimer(value: boolean) {
    toggleShowDisclaimer();
    props.setDisclaimerAcceted("DisclaimerAccepted", value);
  }

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} mt={4}>
        <Typography variant="body2">
          I {firstName} confirm that the information provided in this
          application form is true to the best of my knowledge, and I'm happy
          with the checks which Spectra will complete as detailed above. These
          results may be accessed again if I apply for a tenancy in the future.
        </Typography>
        <Controller
          name="Confirm1"
          control={control}
          render={({ field }) => <Checkbox {...field} sx={{ ml: 1 }} />}
        />
      </Stack>
      {errors.Confirm1 && (
        <FormHelperText error>{errors.Confirm1.message}</FormHelperText>
      )}
      <Stack direction={"row"} alignItems={"center"} mt={4}>
        <Typography variant="body2">
          I {firstName} confirm that this application, my credit check report
          and references be shared with the landlord for enabling the landlord
          to decide and consent to enter into a tenancy agreement
        </Typography>
        <Checkbox
          checked={props.disclaimerAccepted}
          sx={{ ml: 1 }}
          onChange={toggleShowDisclaimer}
        />
      </Stack>
      {errors.DisclaimerAccepted && (
        <FormHelperText error>
          {errors.DisclaimerAccepted.message}
        </FormHelperText>
      )}
      <Dialog
        onClose={toggleShowDisclaimer}
        open={showDisclaimer}
        scroll="paper"
      >
        <TenantApplicationDisclaimer acceptDisclaimer={acceptDisclaimer} />
      </Dialog>
    </>
  );
};
