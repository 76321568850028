import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectProperty } from "../../components/property/selectProperty";
import PropertyModel from "../../models/property/property";
import { AppCtx } from "../../store/store";
import { LandlordModel } from "../../models/property/landlord";
import { getLandlordDetais } from "../../apis/property/landlord";
import ContactDetails from "../../components/property/contactDetails";

export const Landlord = () => {
  const navigate = useNavigate();
  const { setIsLoading, propertiesList, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const { propertyId } = useParams();
  const [landlord, setLandlord] = useState<LandlordModel>();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();
  
  useEffect(() => {
    if (propertyId && loggedUser) {
      fetchLandlord();
    }
  }, [propertyId]);

  useEffect(() => {
    selectedProperty && navigate(`/properties/landlord/${selectedProperty.id}`);
  }, [selectedProperty]);

  const fetchLandlord = async () => {
    setIsLoading(true);
    let tempLandlordDetails = (await getLandlordDetais(
      propertyId!,
      loggedUser?.token!
    )) as unknown as LandlordModel;
    if (tempLandlordDetails) {
      setLandlord(tempLandlordDetails);

      var tempSelectedProperty = propertiesList.find(
        (x) => x.id.toString() === propertyId
      )!;
      if (tempSelectedProperty) {
        setSelectedProperty(tempSelectedProperty);
      }
    }
    setIsLoading(false);
  };

  const handleChangeProperty = (property: PropertyModel) => {
    setSelectedProperty(property);
  };

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 3 }}>
        <CardContent sx={{ p: 3, alignItems: "center" }}>
          {loggedUser?.isLandLord && (
            <SelectProperty
              selectedPropertyId={propertyId!}
              getSelected={handleChangeProperty}
            />
          )}

          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {landlord ? (
              <ContactDetails
                fullName={landlord.firstName + " " + landlord.lastName}
                mainPhone={landlord.mainPhone}
                workPhone={landlord.workPhone}
                mobile={landlord.mobile}
                mail={landlord.email}
                isSuperAdmin={loggedUser?.isSuperAdmin}
              />
            ) : (
              "No landlord found!"
            )}
          </Stack>
          <Divider sx={{ mt: 1, mb: 1 }} />

          <Box display="flex" justifyContent="center">
            <Tooltip title={"Back to property page"}>
              <IconButton
                color="primary"
                onClick={() => navigate("/properties/" + propertyId!)}
              >
                <HighlightOff sx={{ fontSize: 60 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
