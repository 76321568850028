import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext } from "react";
import { TenantApplicationConfirm } from "../../components/TenantApplication/confirm";
import { TenantApplicationHoldingDeposit } from "../../components/TenantApplication/holdingDeposit";
import { TenantApplicationPreApplication } from "../../components/TenantApplication/preApplication";
import { TenantApplicationTenantDetails } from "../../components/TenantApplication/tenantDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TenantApplicationSchema } from "../../schemas/forms/tenantApplication";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";
import { SubmitTenantApplication } from "../../apis/forms/tenantApplication";
import { HighlightOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppCtx } from "../../store/store";

export const TenantApplication: React.FC = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppCtx);
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<TenantApplicationModel>({
    resolver: yupResolver(TenantApplicationSchema),
    defaultValues: {
      Image: "",
      Title: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      PropertyId: "",
      ViewingDate: "",
      RentAmount: "",
      RentFrequency: "PCM",
      Deposit: "",
      TenancyFrom: "",
      TenancyTill: "",
      AnnualIncome: "",
      CCJAdverseHistory: false,
      CCJInformation: "",
      GuarantorRequired: false,
      AdditionalInformation: "",
      AmountPaid: "",
      DateOfPayment: "",
      Confirm1: false,
      DisclaimerAccepted: false,
    },
  });
  const firstName = watch("FirstName");
  const disclaimerAccepted = watch("DisclaimerAccepted");

  const submit = async (tenantApplication: TenantApplicationModel) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("TenantImage", tenantApplication.Image);
    tenantApplication.Image = "";
    formdata.append(
      "ApplicationModelString",
      JSON.stringify(tenantApplication)
    );
    await SubmitTenantApplication(formdata);
    navigate(-1);
    setIsLoading(false);
  };

  return (
    <Container maxWidth={"sm"}>
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 2 }}>
        <CardContent sx={{ p: 3 }}>
          <Box component={"form"} noValidate onSubmit={handleSubmit(submit)}>
            <TenantApplicationTenantDetails
              control={control}
              errors={errors}
              setvalue={setValue}
            />
            <TenantApplicationPreApplication
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <TenantApplicationHoldingDeposit
              control={control}
              errors={errors}
            />
            <TenantApplicationConfirm
              control={control}
              firstName={firstName}
              errors={errors}
              disclaimerAccepted={disclaimerAccepted}
              setDisclaimerAcceted={setValue}
            />
            <Button
              fullWidth
              variant="contained"
              disableElevation
              size="large"
              sx={{ mt: 5, mb: 2, p: 2 }}
              type="submit"
            >
              Submit Application
            </Button>
          </Box>
          <Box display="flex" justifyContent="center">
            <Tooltip title={"Back"}>
              <IconButton color="error" onClick={() => navigate(-1)}>
                <HighlightOff sx={{ fontSize: 60 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
