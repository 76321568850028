import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SelectProperty } from "../../components/property/selectProperty";
import PropertyModel from "../../models/property/property";
import { AppCtx } from "../../store/store";

export default function Property() {
  const navigate = useNavigate();
  const { getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const { propertyId } = useParams();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();

  useEffect(() => {
    selectedProperty && navigate(`/properties/${selectedProperty?.id}`);
  }, [selectedProperty]);

  function handleTenancyButtonClick() {
    navigate(`/properties/tenancy/${propertyId}`);
  }
  function handleChangeProperty(property: PropertyModel) {
    setSelectedProperty(property);
  }
  function handleComplianceButtonClick() {
    navigate(`/properties/compliance/${propertyId}`);
  }
  function handleFinancialButtonClick() {
    navigate(`../financial/${propertyId}`);
  }
  function handleClickLandLord() {
    navigate(`/properties/landlord/${propertyId}`);
  }

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent sx={{ p: 3 }}>
          {loggedUser &&
            (loggedUser?.isLandLord ? (
              <SelectProperty
                selectedPropertyId={propertyId!}
                getSelected={handleChangeProperty}
              />
            ) : (
              <Typography variant="h5" fontWeight="bold">
                Hello - {loggedUser!.name},
              </Typography>
            ))}
          <Box display="flex" flexDirection={"column"} alignItems="center">
            <Button
              color="primary"
              variant="contained"
              sx={{
                marginBottom: 3,
                marginTop: 3,
                width: "100%",
                height: 50,
                borderRadius: 25,
              }}
              onClick={handleComplianceButtonClick}
            >
              compliance
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                marginBottom: 3,
                width: "100%",
                height: 50,
                borderRadius: 25,
              }}
              onClick={handleTenancyButtonClick}
            >
              Tenancy
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                marginBottom: 3,
                width: "100%",
                height: 50,
                borderRadius: 25,
              }}
              onClick={handleFinancialButtonClick}
            >
              Financial
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                marginBottom: 3,
                width: "100%",
                height: 50,
                borderRadius: 25,
              }}
              onClick={handleClickLandLord}
            >
              Landlord
            </Button>
            {loggedUser?.isLandLord ? (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    navigate(`/reports/viewMaintenanceReports/${propertyId!}`)
                  }
                  sx={{
                    marginBottom: 3,
                    width: "100%",
                    height: 50,
                    borderRadius: 25,
                  }}
                >
                  View Report Maintenance
                </Button>
                {loggedUser.isSuperAdmin && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      navigate(`/properties/viewTenantApplications/${propertyId!}`)
                    }
                    sx={{
                      marginBottom: 3,
                      width: "100%",
                      height: 50,
                      borderRadius: 25,
                    }}
                  >
                    View Tenant Applications
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    navigate(`/properties/documents/${propertyId!}`)
                  }
                  sx={{
                    marginBottom: 3,
                    width: "100%",
                    height: 50,
                    borderRadius: 25,
                  }}
                >
                  Documents
                </Button>
                <Link to="/properties">
                  <IconButton color="primary">
                    <HighlightOff sx={{ fontSize: 60 }} />
                  </IconButton>
                </Link>
              </>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("/reports/maintenance")}
                sx={{
                  marginBottom: 3,
                  width: "100%",
                  height: 50,
                  borderRadius: 25,
                }}
              >
                Report Maintenance
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
