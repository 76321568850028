import { createBrowserRouter, redirect } from "react-router-dom";
import { LocalStorage } from "./functions/localstorage";
import MainLayout from "./layouts/mainLayout";
import Login from "./pages/Auth/login";
import PasswrodRecovery from "./pages/Auth/passwordRecovery";
import SignUp from "./pages/Auth/signup";
import { Properties } from "./pages/Property/properties";
import Property from "./pages/Property/property";
import ErrorPage from "./pages/message/error-page";
import { AccountActivated } from "./pages/message/success";
import { Tenancy } from "./pages/Property/tenancy";
import AuthLayout from "./layouts/authLayout";
import { Compliance } from "./pages/Property/compliance";
import { Financial } from "./pages/Property/financial";
import { TenantApplication } from "./pages/Forms/tenantApplication";
import { MaintenanceReporting } from "./pages/Reports/maintenanceReporting";
import { ViewMaintenanceReport } from "./pages/Reports/viewMaintenanceReport";
import { TenancyContractDetails } from "./pages/Documents/TenancyContractDetails";
import { Landlord } from "./pages/Property/landlord";
import { ViewTenantApplications } from "./pages/Property/viewTenantApplication";

async function CheckRootLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (loggedUser) {
    if (loggedUser.isLandLord) {
      route = "properties";
    } else {
      route = "properties/" + loggedUser.tenantPropertyId;
    }
  } else {
    route = "/auth/login";
  }
  return route && redirect(route);
}

function CheckAuthLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (loggedUser) {
    if (loggedUser.isLandLord) {
      route = "properties";
    } else {
      route = "properties/" + loggedUser.tenantPropertyId;
    }
  }
  return route && redirect(route);
}

function CheckLoggedUserAvailable() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (!loggedUser) {
    route = "/auth/login";
  }
  return route && redirect(route);
}

function CheckPropertiesLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (!loggedUser.isLandLord) {
    route = loggedUser.tenantPropertyId.toString();
  }
  return route && redirect(route);
}

function CheckMaintenanceReportStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (loggedUser.isLandLord) {
    route = "/";
  }
  return route && redirect(route);
}

function CheckViewMaintenanceStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (!loggedUser.isLandLord) {
    route = "/";
  }
  return route && redirect(route);
}

export const router = createBrowserRouter([
  {
    path: "/",
    loader: CheckRootLoginStatus,
    errorElement: <ErrorPage />,
  },
  {
    path: "/properties",
    element: <MainLayout />,
    loader: CheckLoggedUserAvailable,
    children: [
      {
        path: "/properties/",
        element: <Properties />,
        loader: CheckPropertiesLoginStatus,
      },
      {
        path: "/properties/:propertyId",
        element: <Property />,
      },
      {
        path: "/properties/tenancy/:propertyId",
        element: <Tenancy />,
      },
      {
        path: "/properties/landlord/:propertyId",
        element: <Landlord />,
      },
      {
        path: "/properties/compliance/:propertyId",
        element: <Compliance />,
      },
      {
        path: "/properties/financial/:propertyId",
        element: <Financial />,
      },
      {
        path: "/properties/documents/:propertyId",
        element: <TenancyContractDetails />,
      },
      {
        path: "/properties/viewTenantApplications/:propertyId",
        element: <ViewTenantApplications />,
      },
    ],
  },
  {
    path: "/auth/",
    element: <AuthLayout />,
    loader: CheckAuthLoginStatus,
    children: [
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/signup",
        element: <SignUp />,
      },
      {
        path: "/auth/password-recovery",
        element: <PasswrodRecovery />,
      },
    ],
  },
  {
    path: "/forms",
    element: <AuthLayout />,
    children: [
      {
        path: "/forms/tenantApplication",
        element: <TenantApplication />,
      },
    ],
  },
  {
    path: "/reports",
    element: <MainLayout />,
    loader: CheckLoggedUserAvailable,
    children: [
      {
        path: "/reports/maintenance",
        element: <MaintenanceReporting />,
        loader: CheckMaintenanceReportStatus,
      },
      {
        path: "/reports/viewMaintenanceReports/:propertyId",
        element: <ViewMaintenanceReport />,
        loader: CheckViewMaintenanceStatus,
      },
    ],
  },
  {
    path: "/message/success",
    element: <AccountActivated />,
  },
]);
