import {
  Container,
  Card,
  CardContent,
  Avatar,
  Button,
  Stack,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SelectProperty } from "../../components/property/selectProperty";
import { useNavigate, useParams } from "react-router-dom";
import { AppCtx } from "../../store/store";
import PropertyModel from "../../models/property/property";
import { convertDate } from "../../functions/modifyText";
import CustomListItemModel from "../../models/shared/customListItem";
import { CustomListItem } from "../../components/shared/customListItem";
import { GetTenantApplicationsByPropertyId } from "../../apis/forms/tenantApplication";
import { showSnackBar } from "../../functions/snackbar";
import { ViewTenantApplicationModel } from "../../models/forms/viewTenantApplicationModel";
import { HighlightOff } from "@mui/icons-material";

export const ViewTenantApplications: React.FC = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { setIsLoading, propertiesList, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();
  const [tenantApplications, setTenantApplications] = useState<
    ViewTenantApplicationModel[]
  >([]);
  const [currentApplication, setCurrentApplication] =
    useState<ViewTenantApplicationModel>();

  useEffect(() => {
    if (propertyId && loggedUser) {
      fetchTenantApplications();
    }
  }, [propertyId]);

  useEffect(() => {
    selectedProperty &&
      navigate(`/properties/viewTenantApplications/${selectedProperty.id}`);
  }, [selectedProperty]);

  const handleChangeApplication = (goToPrevious?: boolean) => {
    if (tenantApplications && tenantApplications.length > 0) {
      let currentApplicationIndex = tenantApplications.indexOf(
        currentApplication!
      );
      var applicationToBeDisplayed = tenantApplications[0];
      if (goToPrevious) {
        if (currentApplicationIndex !== 0) {
          applicationToBeDisplayed =
            tenantApplications[currentApplicationIndex - 1];
        }
      } else if (currentApplicationIndex < tenantApplications.length) {
        applicationToBeDisplayed =
          tenantApplications[currentApplicationIndex + 1];
      }
      if (applicationToBeDisplayed) {
        setCurrentApplication(applicationToBeDisplayed);
      }
    }
  };
  async function fetchTenantApplications() {
    setIsLoading(true);
    await GetTenantApplicationsByPropertyId(propertyId!, loggedUser?.token!)
      .then((response) => {
        if (response.status) {
          let tempMaintenanceReports =
            response.data as unknown as ViewTenantApplicationModel[];
          setTenantApplications(tempMaintenanceReports ?? []);
          if (tempMaintenanceReports && tempMaintenanceReports.length > 0) {
            setCurrentApplication(tempMaintenanceReports[0]);
          }
        } else {
          showSnackBar("error", response.message);
          setTenantApplications([]);
          setCurrentApplication(undefined);
        }
      })
      .catch((error) => showSnackBar("error", error));
    var tempSelectedProperty = propertiesList.find(
      (x) => x.id.toString() === propertyId
    )!;
    if (tempSelectedProperty) {
      setSelectedProperty(tempSelectedProperty);
    }
    setIsLoading(false);
  }

  const customListItems: CustomListItemModel[] = !currentApplication
    ? []
    : [
        {
          title: "Applicant Name",
          value:
            [
              currentApplication?.title,
              currentApplication?.firstName,
              currentApplication?.middleName,
              currentApplication?.lastName,
            ]
              .filter(Boolean)
              .join(" ") || "Not Available",
        },
        { title: "Email", value: currentApplication?.email },
        { title: "Phone Number", value: currentApplication?.phoneNumber },
        { title: "Property ID", value: currentApplication?.propertyId },
        {
          title: "Viewing Date",
          value: convertDate(currentApplication?.viewingDate),
        },
        { title: "Rent Amount", value: currentApplication?.rentAmount },
        { title: "Rent Frequency", value: currentApplication?.rentFrequency },
        { title: "Deposit", value: currentApplication?.deposit },
        {
          title: "Tenancy From",
          value: convertDate(currentApplication?.tenancyFrom),
        },
        {
          title: "Tenancy Till",
          value: convertDate(currentApplication?.tenancyTill),
        },
        { title: "Annual Income", value: currentApplication?.annualIncome },
        {
          title: "CCJ Adverse History",
          value: currentApplication?.ccjAdverseHistory ? "Yes" : "No",
        },
        { title: "CCJ Information", value: currentApplication?.ccjInformation },
        {
          title: "Guarantor Required",
          value: currentApplication?.guarantorRequired ? "Yes" : "No",
        },
        {
          title: "Additional Information",
          value: currentApplication?.additionalInformation,
        },
        { title: "Amount Paid", value: currentApplication?.amountPaid },
        {
          title: "Date of Payment",
          value: convertDate(currentApplication?.dateOfPayment),
        },
      ];

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <SelectProperty
            selectedPropertyId={propertyId!}
            getSelected={setSelectedProperty}
          />
          {tenantApplications.length === 0 ? (
            <Box height={"50vh"}>
              <Typography mt={2} textAlign={"center"} color={"red"}>
                No Tenant Applications found!
              </Typography>
            </Box>
          ) : (
            <>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                mt={2}
              >
                <Avatar
                  src={
                    currentApplication
                      ? `data:image/jpeg;base64,${currentApplication.photoUrl}`
                      : "/images/person.png"
                  }
                  variant="square"
                  sx={{
                    width: "300px",
                    height: "300px",
                    margin: "auto",
                    objectFit: "contain",
                    mt: 1,
                    borderRadius: "10px",
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                m={2}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleChangeApplication(true);
                  }}
                  disabled={
                    tenantApplications.length! === 0 ||
                    tenantApplications.indexOf(currentApplication!) === 0
                  }
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleChangeApplication();
                  }}
                  disabled={
                    tenantApplications.indexOf(currentApplication!) ===
                    tenantApplications.length! - 1
                  }
                >
                  Next
                </Button>
              </Stack>
              <Box mt={2}>
                {customListItems.map((item, index) => (
                  <CustomListItem key={index} index={index} item={item} />
                ))}
              </Box>
            </>
          )}
          <Box display="flex" justifyContent="center">
            <Tooltip title={"Back to property page"}>
              <IconButton
                color="primary"
                onClick={() => navigate("/properties/" + propertyId!)}
              >
                <HighlightOff sx={{ fontSize: 60 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
