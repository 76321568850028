import { Grid, Typography } from "@mui/material";
import React from "react";

interface Props {
  fullName: string;
  mainPhone?: string;
  workPhone?: string;
  mobile?: string;
  mail?: string;
  isSuperAdmin?: boolean;
}

const ContactDetails: React.FC<Props> = (props) => {
  return (
    <Grid container spacing={2} justifyContent="center" mt={2}>
      <Grid item xs={12}>
        <Typography
          component="div"
          variant="h6"
          fontSize={"md"}
          fontWeight={"bold"}
          color="secondary"
          textAlign={"center"}
        >
          {props.fullName}
        </Typography>
      </Grid>
      {props.isSuperAdmin && (
        <>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold">
              Main Phone:
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="body2">
              {props.mainPhone ? (
                <a href={`tel:${props.mainPhone}`}>{props.mainPhone}</a>
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold">
              Work Phone:
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="body2">
              {props.workPhone ? (
                <a href={`tel:${props.workPhone}`}>{props.workPhone}</a>
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold">
              Mobile:
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="body2">
              {props.mobile ? (
                <a href={`tel:${props.mobile}`}>{props.mobile}</a>
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>

          {/* Email */}
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold">
              Email:
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography variant="body2">
              {props.mail ? (
                <>
                  {props.mail.split(",").map((x) => (
                    <a href={`mailto:${x}`}>{x}</a>
                  ))}
                </>
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ContactDetails;
